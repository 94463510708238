











































































































































































































import { defineComponent, reactive, toRefs, ref } from '@vue/composition-api'
import $http from '@/api'

export default defineComponent({
  name: 'WisdomClassroomDesk',
  setup() {
    // 产品优势
    const productAdvantage = [
      {
        title: '轻松备课',
        icon: 'qsbk',
        text: '提供功能强大、教学资源丰富的PPT备课插件，不改变教师备课习惯即可快速完成优质交互式课件的制作。'
      },
      {
        title: '优质资源',
        icon: 'yzzy',
        text: '拥有海量优质的同步配套教学资源，类型丰富、互动性强，助力高质量智慧教学。'
      },
      {
        title: '精准教学',
        icon: 'jzjx',
        text: '通过课前预习、课中互动、课后作业练习的学情分析报告，轻松掌握学生学习情况，实现以学定教。'
      },
      {
        title: '全场景授课',
        icon: 'qcjsk',
        text: '覆盖全部学科和学段的教学全场景，支持通过电子白板、电子课本、PPT课件进行授课，同时支持对作业考试报告的讲评。'
      },
      {
        title: '课堂互动',
        icon: 'kthd',
        text: '支持多种形式的课堂师生互动，如随堂练习、抢答、随机提问、分组PK等，以“学生为主体”，有效提升课堂教学效果。'
      },
      {
        title: '云端同步',
        icon: 'ydtb',
        text: '师生的教学资源、互动数据、学情报告等云端实时同步保存，在不同教学终端登录账号即可随时查看使用。'
      }
    ]
    const PRODUCT = {
      PC: 2, //主讲端
      SCREEN: 1 //大屏端
    }
    const productId = ref(PRODUCT.PC)

    // 下载助手安装包
    const handleDownload = async (id: number) => {
      productId.value = id
      const { data } = await $http.getDownloadUrl({
        productId: productId.value
      })
      window.location.href = data.downloadUrl
    }

    const showState = reactive({
      wisdom: false,
      advantage: false
    })

    const pics = {
      product: require('./assets/product-formation.png'),
      oneBg: require('../../assets/one-bg.png'),
      twoBg: require('./assets/two-bg.png'),
      threeBg: require('./assets/three-bg.png'),
      fourBg: require('./assets/four-bg.png'),
      img1: require('./assets/screen-3.png'),
      img2: require('./assets/screen-2.png'),
      img3: require('./assets/screen-1.png'),
      pc: require('./assets/PC.png'),
      backimg: require('./assets/back.png'),
      frontimg: require('./assets/front.png')
    }

    const handleSetShow = (type: string) => (showState[type] = true)

    return {
      ...toRefs(showState),
      productId,
      PRODUCT,
      pics,
      productAdvantage,
      handleDownload,
      handleSetShow
    }
  }
})
